import { Autoplay, Lazy, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import dataBenefits from './data/beneficios.json'
import './benefitsCarousel.scss'

const BenefitsCarousel = () => {
  return (
    <div className="benefits-mktplace-container">
      <h2>Benefícios</h2>
      <div className="wrapper-swiper">
        <Swiper
          navigation
          breakpoints={{
            1440: {
              slidesPerView: 5,
            },
            1244: {
              slidesPerView: 5,
            },
            1150: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 4,
            },
            1000: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            425: {
              slidesPerView: 2,
            },
            330: {
              slidesPerView: 2,
            },
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }}
          spaceBetween={16}
          autoplay={{
            delay: 15000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop
          modules={[Autoplay, Navigation, Lazy]}
          className="marketplace-benefits-swiper"
        >
          {dataBenefits.map((benefit, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="benefit-card">
                  <img
                    id="desk"
                    style={{ maxWidth: benefit.maxWidthImage }}
                    src={benefit.image}
                    alt={`${benefit.title} marketplace | Decathlon`}
                  />
                  <img
                    id="mob"
                    style={{ maxWidth: benefit.maxWidthImageMob }}
                    src={benefit.image}
                    alt={`${benefit.title} marketplace | Decathlon`}
                  />
                  <h2>{benefit.title}</h2>
                  <p id="desk" style={{ maxWidth: benefit.maxWidthDesciption }}>
                    {benefit.description}
                  </p>
                  <p
                    id="mob"
                    style={{ maxWidth: benefit.maxWidthDesciptionMobile }}
                  >
                    {benefit.description}
                  </p>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default BenefitsCarousel
